<template>

  <div class="w-100">

    <b-container fluid="md" class="text-section py-4">
      <b-row>
        <b-col class="text-center"><h2>{{ $t('what.title') }}</h2></b-col>
      </b-row>
      <b-row>
        <b-col v-html="$t('what.content')">
        </b-col>
      </b-row>
    </b-container>

  </div>

</template>
<script>
export default {
  name: 'What'
}
</script>
