<template>
  <div class="w-100 border-top border-gray">

    <b-container class="text-section py-4 ">
      <b-row>
        <b-col class="text-center"><h2>{{ $t('live.title') }}</h2></b-col>
      </b-row>
      <b-row>
        <b-col v-html="$t('live.content')">
        </b-col>
      </b-row>
<!--      <b-row>-->
<!--        <b-col class="text-center">-->
<!--          <b-button class="text-decoration-none" @click="$scrollTo('#finance')" variant="primary" :title="$t('finance.btn-title')">{{ $t('finance.btn-title') }}</b-button>-->
<!--        </b-col>-->
<!--      </b-row>-->
    </b-container>

  </div>
</template>
<script>
export default {
  name: 'Live'
}
</script>
