<template>
  <div v-show="isVisible" class="jump-top pointer">
    <b-icon icon="caret-up-square-fill" font-scale="2.5" :variant="colorVariant" @click="$scrollTo('#app')"></b-icon>
  </div>
</template>
<script>
export default {
  name: 'JumpTop',
  props: {
    isVisible: {type: Boolean, default: false},
    colorVariant: {type: String }
  }
}
</script>
