import Vue from 'vue'

import {
  ButtonPlugin,
  LayoutPlugin,
  ImagePlugin,
  IconsPlugin,
  OverlayPlugin,
  SpinnerPlugin,
  VBVisiblePlugin
} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(ButtonPlugin)
Vue.use(LayoutPlugin)
Vue.use(ImagePlugin)
Vue.use(IconsPlugin)
Vue.use(OverlayPlugin)
Vue.use(SpinnerPlugin)
Vue.use(VBVisiblePlugin)
