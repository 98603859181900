import '@babel/polyfill'
import 'mutationobserver-shim'


import Vue from 'vue'
import './plugins/bootstrap-vue'
import Service from './App.vue'

import '~sass/app.scss'

// localization
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'de',
  // messages: {
  //   'de': require('~/locales/de.json')
  // }
})

// xhr
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// scroll to
import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

// sanitize loaded html input
import VueSecureHTML from 'vue-html-secure'
Vue.use(VueSecureHTML)

// meta data
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.prototype.$app = {
  firstVideoRequest: true
}

Vue.config.silent = true
Vue.config.productionTip = false



new Vue({
  i18n,
  render: h => h(Service),
}).$mount('#app')
