<template>
  <div class="d-flex justify-content-center vh-100">
    <div class="my-auto text-center">
      <b-spinner type="grow" variant="primary"></b-spinner><br />
      <h2 class="mt-4">Gleich gehts los...</h2>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Spinner'
}
</script>
