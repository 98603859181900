<template>
  <div class="w-100 bg-primary imprint">

<!--    <b-container fluid="md" class="text-section py-4">-->
<!--    </b-container>-->

    <b-container fluid="md" class="text-section py-4" id="finance">
      <b-row>
        <b-col class="text-center"><h2>{{ $t('service.title') }}</h2></b-col>
      </b-row>
      <b-row>
        <b-col class="text-center"><h3>{{ $t('finance.title') }}</h3></b-col>
      </b-row>
      <b-row cols="1" cols-sm="2">
        <b-col v-html="$t('finance.content')"></b-col>
        <b-col>
          <h4>{{ $t('finance.yt.headline') }}</h4>
          <p>
            <b-button :href="$t('finance.yt.web')" variant="light" class="text-decoration-none" :title="$t('finance.yt.title')">
              <b-icon icon="globe2"></b-icon> {{ $t('finance.yt.title') }}
            </b-button>
          </p>
          <h4>{{ $t('finance.paypal') }}</h4>
          <form action="https://www.paypal.com/donate" method="post" target="_blank" class="pt-2 pb-4 px-2">
            <input type="hidden" name="hosted_button_id" value="N7D4AW8MYNJQJ">
            <input type="submit" name="submit" :title="$t('finance.btn')" :value="$t('finance.btn')" class="btn btn-light">
          </form>
          <h4>{{ $t('finance.transfer.title') }}</h4>
          <p v-html="$t('finance.transfer.data')"></p>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid="md" class="text-section py-4" id="contact">
      <b-row>
        <b-col class="text-center"><h3>{{ $t('contact.title') }}</h3></b-col>
      </b-row>
      <b-row cols="1" cols-sm="2">
        <b-col>
          <p v-html="$t('contact.address')"></p>
        </b-col>
        <b-col>
          <p>
            <b-button :href="'mailto:'+$t('contact.mail')" variant="light" class="text-decoration-none" :title="$t('contact.mail-title')">
              <b-icon icon="envelope"></b-icon> {{ $t('contact.mail') }}
            </b-button>
          </p>
          <p>
            <b-button :href="'tel:'+$t('contact.telephone')" variant="light" class="text-decoration-none" :title="$t('contact.telephone-title')">
              <b-icon icon="telephone"></b-icon> {{ $t('contact.telephone') }}
            </b-button>
          </p>
        </b-col>
      </b-row>
      <b-row cols="1" cols-sm="2">
        <b-col>
          <p v-html="$t('imprint.tech')"></p>
        </b-col>
        <b-col>
          <p>
            <b-button :href="'https://'+$t('imprint.tech-web')" target="_blank" variant="light" class="text-decoration-none" :title="$t('imprint.tech-web')">
              <b-icon icon="globe2"></b-icon> {{ $t('imprint.tech-web') }}
            </b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid="md" class="text-section py-4" id="privacy">
      <b-row>
        <b-col class="text-center text-light"><h3>{{ $t('dsgvo.title') }}</h3></b-col>
      </b-row>
      <b-row cols="1" cols-sm="2">
        <b-col v-html="$t('dsgvo.content1')"></b-col>
        <b-col v-html="$t('dsgvo.content2')"></b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: 'Service'
}
</script>
