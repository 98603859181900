<template>
  <div id="app" class="bg-zodiacs">
    <Spinner v-if="loading" />
    <template v-else>
      <component :is="title" :videos="videos" v-b-visible="handleVisibleTitle"/>
      <Live />
      <component :is="archive" :videos="videos" />
      <What />
      <Service v-b-visible="handleVisibleService"/>
      <JumpTop :is-visible="!isTitleVisible" :color-variant="jumpTopColorVariant"/>
    </template>
  </div>
</template>

<script>

import JumpTop from './JumpTop'
import Live from './components/Live'
import What from './components/What'
import Service from '~component/Service'
import Spinner from '~component/Spinner'

const Title = () => import ('~component/Title')
const Archive = () => import ('~component/Archive')

export default {
  name: 'App',
  data: () => {
    return {
      loading: true,
      title: null,
      archive: null,
      videos: null,
      isTitleVisible: true,
      jumpTopColorVariant: 'primary'
    }
  },
  components: {
    Spinner,
    JumpTop,
    Live,
    What,
    Service,
  },
  beforeMount () {
    this.$http.all([
        this.$http.get('videos.json?t='+this.getTimestamp()).catch(this.useNull),
        this.$http.get('de.json?t='+this.getTimestamp()).catch(this.useNull)
    ]).then(this.$http.spread((videos, lang) => {
      this.$i18n.setLocaleMessage('de', lang.data)
      this.videos = videos.data
      this.title = Title
      this.archive = Archive
      this.loading = false
    })).catch(() => console.log('error loading data'))
  },
  methods: {
    useNull: () => { return null },
    getTimestamp: () => { return new Date().getTime() },
    handleVisibleTitle: function (isVisible) {
      this.isTitleVisible = isVisible
    },
    handleVisibleService: function (isVisible) {
      this.jumpTopColorVariant = (isVisible) ? 'light' : 'primary'
    }
  }

}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
